.card {
    width: 20vw;
    margin:0 3rem;
}
.card-body {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    text-align: center;
    position: relative;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30vh;
    border-radius: 1rem 1rem 0 0;
    background-color: var(--color-main);
}

.image-container img {
    height: 100%;
    border-radius: 1rem;
    transition: height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
}

.image-container:hover img {
    height: 300px;
    margin-bottom: 1.3rem;
}

.card-text {
    margin-top: 1rem;
}

.card-text p {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: var(--color-main);
}

.card-text h3 {
    font-size: 26px;
    font-weight: 500;
    text-align: left;
    color: var(--primary-text);
    margin-top: 1rem;
}
.social-media {
    display: flex;
    justify-content: left;
}
.social-media a {
    margin-top: 0.5rem;
}
@media screen and (max-width: 1500px) {
    .card {
        width: 50vw;
    }
}
@media screen and (max-width: 900px) {
    .card {
        width: 50vw;
        margin-bottom: 5rem;
    }
    .image-container:hover img {
        height: 100%;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 600px) {
    .card {
        width: 65vw;
    }
}