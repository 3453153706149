.form {
    display: flex;
    justify-content: center;
    width: 40vw;
}
.form form {
    width: 100%;
}
.form-top {
    display: flex;
    flex-direction: row;
}
.form-subject {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-right: 1rem;
    width: 100%;
}
.form-subject input {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color-line);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}
.form-email {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
}
.form-email input {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color-line);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}
.form-message {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.form-message textarea {
    border: none;
    outline: none;
    resize: none;
    border-bottom: 1px solid var(--color-line);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 15vh;
}
.form-budget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.form-budget select {
    -webkit-border-radius: 2px;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color-line);
    font-size: 16px;
    font-weight: 500;
    color: var(--color-subtext);
    height: 3vh;
}
.form-budget label {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-subtext);
    margin-bottom: 1rem;
}
.form-checkbox {
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.form-checkbox-label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.form-checkbox-label input[type='checkbox'] {
    display: none;
}
.form-checkmark {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #e2e2e2;
}
.form-checkbox-label:hover input ~ .form-checkmark {
  background-color: #ccc;
}
.form-checkbox-label input:checked ~ .form-checkmark {
  background-color: var(--color-main);
}
.form-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.form-checkbox-label input:checked ~ .form-checkmark:after {
  display: block;
}
.form-checkbox-label .form-checkmark:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-send {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}
.form-send button {
    cursor: pointer;
    background-color: var(--color-main);
    color: white;
    border: none;
    padding: 0.8rem 1.75rem;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
}
.form-send button:hover {
    color: #fff;
    background-image: linear-gradient(45deg,#9900ff,#d424eb);
    transform: translateY(-3px);
    box-shadow: 0 15px 50px -10px #af38ff;
}
@media screen and (max-width: 1500px) {
    .form {
        width: 50vw;
    }
}
@media screen and (max-width: 1200px) {
    .form {
        width: 70vw;
    }
}