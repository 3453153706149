.contact-us {
    display: flex;
    flex-direction: column;
    height: 55vh;
}
.contact-us_header {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}
.contact-us_header h1 {
    margin-top: 5rem;
    color: var(--color-main);
}
.contact-us_form {
    display: flex;
    justify-content: center;
}