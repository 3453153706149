.background-image {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100vh;
    background-image: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-size: cover;
}  
.hero {
    display: flex;
    height: 88vh;
    background-color: var(--color-bg);
}
.hero-content {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 5rem;
}
.hero-content h1 {
    font-weight: 800;
    font-size: 72px;
    line-height: 75px;
    letter-spacing: -0.04em;
}
.hero-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-top: 2rem;
}
.hero-content button {
    cursor: pointer;
    background-color: var(--color-main);
    color: white;
    border: none;
    padding: 0.8rem 1.75rem;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 1.5rem;
    transition: transform .3s,box-shadow .3s,background-color .3s;
}
.hero-content button:hover {
    color: #fff;
    background-image: linear-gradient(45deg,#9900ff,#d424eb);
    transform: translateY(-3px);
    box-shadow: 0 15px 50px -10px #af38ff;
}
@media screen and (max-width: 1400px) {
    .background-image {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .hero-content h1 {
        font-size: 60px;
    }
    .hero-content p {
        font-size: 16px;
        line-height: 24px;
    }
    .hero-content button {
        font-size: 14px;
        padding: 0.7rem 1.5rem;
    }
}