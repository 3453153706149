.about-us {
    display: flex;
    flex-direction: column;
    height: 50vh;
    background-color: white;
}
.about-us h1 {
    text-align: center;
    margin-top: 5rem;
    color: var(--color-main);
    margin-bottom: 1rem;
}
.about-us p {
    text-align: center;
    font-size: 18px;
    margin: 1rem 15rem 0;
    line-height: 2rem;
    color: var(--color-subtext);
}
@media screen and (max-width: 1400px) {
    .about-us p {
        margin: 1rem 8rem 0;
    }
}
@media screen and (max-width: 1200px) {
    .about-us p {
        margin: 1rem 2rem 0;
    }
}
@media screen and (max-width: 900px) {
    .about-us {
        height: 100%;
    }
    .about-us p {
        margin: 1rem 0rem 0;
        font-size: 16px;
    }
}