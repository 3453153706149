.navbar-content {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 2rem 12rem;
    background-color: var(--color-bg);
}
.navbar-content_links {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar-content_links img {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 50px;
    margin-left: 12rem;
    user-select: none;
}
.navbar-content_links a {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none;
    color: var(--color-text);
    margin: 0 1rem;
    cursor: pointer;
}
.navbar-content_links button {
    padding: 0.75rem 2rem;
    color: #fff;
    background: var(--color-main);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform .3s,box-shadow .3s,background-color .3s;
}
.navbar-content_links button:hover {
    color: #fff;
    background-image: linear-gradient(45deg,#9900ff,#d424eb);
    transform: translateY(-3px);
    box-shadow: 0 10px 30px -10px #c46cff;
}
@media screen and (max-width: 1200px) {
    .navbar-content {
        padding: 2rem 8rem; 
    }
    .navbar-content_links img {
        margin-left: 8rem;
    }
}
@media screen and (max-width: 800px) {
    .navbar-content {
        padding: 2rem 4rem; 
    }
    .navbar-content_links img {
        margin-left: 4rem;
    }
}
@media screen and (max-width: 500px) {
    .navbar-content {
        padding: 2rem 2rem; 
    }
    .navbar-content_links img {
        margin-left: 2rem;
    }
    .navbar-content_links a {
        font-size: 14px;
    }
    .navbar-content_links button {
        font-size: 14px;
        padding: 0.6rem 1.1rem;
    }
}