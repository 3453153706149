.our-services {
    display: flex;
    flex-direction: column;
    height: 60vh;
    background-color: white;
}
.our-services h1 {
    text-align: center;
    margin-top: 5rem;
    color: var(--color-main);
}
.our-services_cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@media screen and (max-width: 1300px) {
    .our-services_cards {
        margin: 0 3rem 0;
    }
}
@media screen and (max-width: 900px) {
    .our-services_cards {
        flex-direction: column;
        align-items: center;
    }
    .our-services {
        height: 100%;
    }
}
@media screen and (max-width: 500px) {
    .our-services_cards {
        flex-direction: column;
        align-items: center;
    }
    .our-services {
        height: 100%;
    }
}