* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
}
body {
}
::selection {
    background-color: #79d5ff;
}
.app {
    position: relative;
}
.fixed {
    display: flex;
    position: fixed;
}
.section-padding {
    padding: 4rem 12rem;
}
.section-margin {
    padding: 4rem 6rem;
}
@media screen and (max-width: 1200px) {
    .section-padding {
        padding: 4rem 8rem; 
    }
}
@media screen and (max-width: 800px) {
    .section-padding {
        padding: 4rem 4rem; 
    }
}
@media screen and (max-width: 500px) {
    .section-padding {
        padding: 4rem 2rem; 
    }
}