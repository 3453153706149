.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: var(--color-text);
    color: white;
    font-family: var(--font-family);
    height: 15vh;
    width: 100%;
    align-items: center;
    margin-top: 15rem;
}
.footer-content {
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: 5rem;
    width: 100%;
}
.footer-content h1 {
    font-size: 24px;
}
.footer-content p {
    margin-left: 3rem;
}
.footer-content a {
    cursor: pointer;
    text-decoration: none;
    margin-left: 3rem;
}
.footer-content_email {
    display: flex;
    position: absolute;
    right: 30px;
}
.footer-content_email a {
    text-align: right;
    margin-right: 5rem;
    color: white;
}
@media screen and (max-width: 800px) {
    .footer-content h1 {
        font-size: 20px;
    }
    .footer-content p {
        font-size: 14px;
    }
    .footer-content a {
        font-size: 14px;
    }
}
@media screen and (max-width: 650px) {
    .footer-content {
        margin-left: 2rem;
    }
    .footer-content h1 {
        font-size: 18px;
    }
    .footer-content p {
        font-size: 12px;
        margin-left: 1rem;
    }
    .footer-content a {
        font-size: 12px;
        margin-left: 1rem;
    }
    .footer-content_email a {
        margin-right: 5rem;
    }
}