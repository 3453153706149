.service-card {
    display: flex;
    width: 20vw;
    height: 30vh;
    border: 1px solid var(--color-line);
    box-shadow: 0 15px 36px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    margin: 1rem;
}
.service-card_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    text-align: center;
    margin-bottom: 1rem;
}
.service-card_body p {
    font-size: 18px;
    font-weight: 600;
    margin: 0 3rem;
    color: var(--color-line);
}
.service-card_body h3 {
    font-size: 22px;
    font-weight: 500;
    color: var(--primary-text);
    margin:1rem 0 1rem;
}
@media screen and (max-width: 1300px) {
    .service-card {
        width: 50vw;
    }
}
@media screen and (max-width: 850px) {
    .service-card {
        width: 50vw;
    }
}
@media screen and (max-width: 600px) {
    .service-card {
        width: 70vw;
    }
}