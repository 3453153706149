.team {
    display: flex;
    flex-direction: column;
    height: 80vh;
    background-color: white;
}
.team h1 {
    text-align: center;
    margin-top: 5rem;
    color: var(--color-main);
}
.team-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width: 900px) {
    .team {
        height: 100%;
    }
    .team-cards {
        flex-direction: column;
        align-items: center;
    }
}