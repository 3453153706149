.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}
.banner-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: var(--color-bg);
}
.banner-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 28px;
    line-height: 45px;
    color: var(--color-bg);
}
.banner-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
.banner-btn button {
    background: #000000;
    color: #FFFFFF;
    border-radius: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}